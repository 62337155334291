import "./styles.scss";

import React, { useState, useRef } from "react";
import Slider from "react-slick";

import ArrowIcon from "icons/arrow.svg";

const mainClass = "home-gallery__slider";

const CustomArrow = (props) => {
  const { className, onClick } = props;
  return (
    <button>
      <ArrowIcon className={className} onClick={onClick} />
    </button>
  );
};

const GallerySlider = ({ items }) => {
  const slider = useRef(null);
  const [current_slide, setCurrentSlide] = useState(0);

  const slider_settings = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    draggable: true,
    centerMode: true,
    centerPadding: "200px",
    useTransform: false,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
    },
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 760,
        settings: {
          centerPadding: "0px",
        },
      },
    ],
  };

  return (
    <Slider ref={slider} className={`${mainClass}`} {...slider_settings}>
      {items?.map(({ localFile }, index) => (
        <div key={index}>
          <div
            className={`${mainClass}__item`}
            style={{ backgroundImage: `url(${localFile?.publicURL})` }}
          >
            {current_slide === index && (
              <>
                <CustomArrow
                  className={`${mainClass}__item__arrow ${mainClass}__item__arrow--prev`}
                  onClick={() => slider?.current?.slickPrev()}
                />
                <CustomArrow
                  className={`${mainClass}__item__arrow ${mainClass}__item__arrow--next`}
                  onClick={() => slider?.current?.slickNext()}
                />
              </>
            )}
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default GallerySlider;
