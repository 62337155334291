import "./styles.scss";

import React from "react";

const mainClass = "home-business-model";

const BusinessModel = ({
  business_model_title,
  business_model_subtext,
  business_model_text,
  business_model_items,
}) => {
  return (
    <section id="model-biznesowy-section" className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__text`}>
          <h3>{business_model_title}</h3>
          <span
            dangerouslySetInnerHTML={{
              __html: business_model_subtext,
            }}
          />
          <p>{business_model_text}</p>
        </div>
        <div className={`${mainClass}__items`}>
          {business_model_items?.map(({ icon, text }, index) => (
            <div key={index} className={`${mainClass}__items__item`}>
              <div key={index} className={`${mainClass}__items__item__icon`}>
                <img src={icon?.localFile?.publicURL} alt="" />
              </div>
              <span
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BusinessModel;
