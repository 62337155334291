import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import ContactModal from "components/ContactModal";
import {
  Hero,
  AskAboutOffer,
  AboutInvestment,
  StepsModel,
  AdvantagesInvestment,
  Apartments,
  Gallery,
  BusinessModel,
  Partners,
} from "page_components/home";

import scrollToElement from "utils/scrollToElement";

const Home = ({ location, data }) => {
  const { hash, pathname, search } = location;
  const [init_loading, setInitLoading] = useState(false);
  const [contact_modal_open, setContactModalOpen] = useState(null);

  const acfHome = data?.homePage?.nodes?.[0]?.acfHome;

  useEffect(() => {
    setInitLoading(true);
  }, [hash]);

  useEffect(() => {
    if (!!!hash) return;

    if (!!init_loading) {
      scrollToElement(`${hash.split("?")[0]}-section`);
    } else {
      setTimeout(() => {
        scrollToElement(`${hash.split("?")[0]}-section`);
      }, 100);
    }

    setInitLoading(true);

    // eslint-disable-next-line
  }, [location]);

  return (
    <Layout
      seo={{
        title: "Sea Shell - apartamenty nad morzem na sprzedaż",
        description:
          "Apartamenty inwestycyjne na sprzedaż nad morzem. Zainwestuj 90 metrów od Bałtyku i zarabiaj na condo.",
      }}
      location={{ pathname, hash }}
      search={search}
      apartments={[]}
      setContactModalOpen={setContactModalOpen}
      phone={acfHome?.contactNumber1}
      phone2={acfHome?.contactNumber2}
      email={acfHome?.contactEmail}
      popup={acfHome?.popup?.localFile?.publicURL}
      popupLink={acfHome?.popupLink}
    >
      <Hero data={acfHome?.slider} />
      <AskAboutOffer
        search={search}
        title={acfHome?.formTitle}
        subtitle={acfHome?.formSubtitle}
      />
      <AboutInvestment
        data={acfHome?.content}
        magical_microclimate_title={acfHome?.magicalMicroclimateTitle}
        magical_microclimate_text={acfHome?.magicalMicroclimateText}
      />
      <StepsModel />
      <AdvantagesInvestment
        slider_items={acfHome?.advantages}
        more_data={{
          advantages_location_title: acfHome?.advantagesLocationTitle,
          advantages_location_text: acfHome?.advantagesLocationText,
          advantagesLocation: acfHome?.advantagesLocation,
        }}
      />
      <BusinessModel
        business_model_title={acfHome?.businessModelTitle}
        business_model_subtext={acfHome?.businessModelSubtext}
        business_model_text={acfHome?.businessModelText}
        business_model_items={acfHome?.businessModelItems}
      />
      <Apartments
        data={acfHome?.investments}
        header_title={acfHome?.investmentsTitle}
        header_text={acfHome?.investmentsText}
        setContactModalOpen={setContactModalOpen}
      />
      <Gallery
        gallery_items={acfHome?.gallery}
        daily_items={acfHome?.buildingDaily?.[0]?.buildingDaily}
      />
      <Partners data={acfHome?.partnersContent} />
      {contact_modal_open && (
        <ContactModal
          search={search}
          investment={contact_modal_open?.investment}
          onExit={() => setContactModalOpen(null)}
        />
      )}
    </Layout>
  );
};

export const query = graphql`
  query {
    homePage: allWpPage(filter: { id: { eq: "cG9zdDoxOA==" } }) {
      nodes {
        id
        acfHome {
          slider {
            title
            text
            image {
              desktop {
                localFile {
                  publicURL
                }
              }
              mobile {
                localFile {
                  publicURL
                }
              }
            }
          }
          popup {
            localFile {
              publicURL
            }
          }
          popupLink
          formTitle
          formSubtitle
          content {
            subText
            text
            title
            image {
              localFile {
                publicURL
              }
            }
          }
          advantages {
            text
            image {
              localFile {
                publicURL
              }
            }
          }
          buildingDaily {
            buildingDaily {
              date
              images {
                localFile {
                  publicURL
                }
              }
            }
          }
          gallery {
            localFile {
              publicURL
            }
          }
          investmentsTitle
          investmentsText
          investments {
            area
            building
            floor
            image {
              localFile {
                publicURL
              }
            }
            name
            plan {
              localFile {
                publicURL
              }
            }
            price
            promotionPrice
            lowestPrice
            rooms
            profit
            underConstruction
          }
          magicalMicroclimateTitle
          magicalMicroclimateText
          advantagesLocationTitle
          advantagesLocationText
          advantagesLocation {
            text
            image {
              localFile {
                publicURL
              }
            }
          }
          businessModelTitle
          businessModelSubtext
          businessModelText
          businessModelItems {
            icon {
              localFile {
                publicURL
              }
            }
            text
          }
          partnersContent {
            subText
            text
            title
          }
          contactNumber1
          contactNumber2
          contactEmail
        }
      }
    }
  }
`;

export default Home;
