import "./styles.scss";

import React from "react";
import Slider from "react-slick";

import ArrowIcon from "icons/arrow.svg";

const mainClass = "home-hero";

function CustomArrow(props) {
  const { className, onClick } = props;
  return <ArrowIcon className={className} onClick={onClick} />;
}

const Hero = ({ data }) => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 2000,
    useTransform: false,
    nextArrow: (
      <CustomArrow className="home-hero__arrow home-hero__arrow--next" />
    ),
    prevArrow: (
      <CustomArrow className="home-hero__arrow home-hero__arrow--prev" />
    ),
  };

  return (
    <section className={mainClass}>
      <div className="container">
        <Slider {...sliderSettings}>
          {data?.map(({ title, text, image }, index) => (
            <div key={index}>
              <div className={`${mainClass}__slide`}>
                <div className={`${mainClass}__text`}>
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: title,
                    }}
                  />
                  <h2
                    dangerouslySetInnerHTML={{
                      __html: text,
                    }}
                  />
                </div>
                <div
                  className={`${mainClass}__slide__background${
                    !image?.desktop?.localFile?.publicURL
                      ? ` ${mainClass}__slide__background--static`
                      : ""
                  }`}
                >
                  {image?.desktop?.localFile?.publicURL && (
                    <>
                      <div
                        className={`${mainClass}__slide__background-image ${mainClass}__slide__background-image--desktop`}
                        style={{
                          backgroundImage: `url(${image.desktop.localFile.publicURL})`,
                        }}
                      />
                      <div
                        className={`${mainClass}__slide__background-image ${mainClass}__slide__background-image--mobile`}
                        style={{
                          backgroundImage: `url(${
                            image?.mobile?.localFile?.publicURL ??
                            image.desktop.localFile.publicURL
                          })`,
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Hero;
