import "./styles.scss";

import React from "react";
import Slider from "react-slick";

import ArrowIcon from "icons/arrow.svg";

const mainClass = "home-advantages-investment__slider";

const CustomArrow = (props) => {
  const { className, onClick } = props;
  return (
    <button>
      <ArrowIcon className={className} onClick={onClick} />
    </button>
  );
};

const AdvantagesInvestmentSlider = ({ items }) => {
  const slider_settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
    nextArrow: (
      <CustomArrow
        className={`${mainClass}__arrow ${mainClass}__arrow--next`}
      />
    ),
    prevArrow: (
      <CustomArrow
        className={`${mainClass}__arrow ${mainClass}__arrow--prev`}
      />
    ),
    responsive: [
      {
        breakpoint: 1560,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className={mainClass}>
      <Slider {...slider_settings}>
        {items?.map(({ image, text }, index) => (
          <div key={index}>
            <div className={`${mainClass}__item`}>
              <div className={`${mainClass}__item__image`}>
                <img src={image?.localFile?.publicURL} alt="" />
              </div>
              <span
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default AdvantagesInvestmentSlider;
