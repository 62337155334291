import "./styles.scss";

import React from "react";

import FormContent from "components/Form";

const mainClass = "home-ask-about-offer";

const AskAboutOffer = ({ search, title, subtitle }) => {
  return (
    <section className={mainClass}>
      <div id="zapytaj-section" className="container">
        <div className={`${mainClass}__header`}>
          {title && <h4>{title}</h4>}
          {subtitle && <span>{subtitle}</span>}
        </div>
        <div className={`${mainClass}__content`}>
          <div className={`${mainClass}__content__wrapper`}>
            <FormContent search={search} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AskAboutOffer;
