import "./styles.scss";

import React, { useState, useRef } from "react";
import Slider from "react-slick";
import classnames from "classnames";

import Button from "components/Button";
// import Select from "components/Select";

import CordsIcon from "icons/cords.svg";
import MessagesIcon from "icons/messages.svg";
import PdfIcon from "icons/pdf.svg";
import ArrowIcon from "icons/arrow.svg";

const mainClass = "home-about-apartments";

const CustomArrow = (props) => {
  const { className, onClick } = props;
  return (
    <button>
      <ArrowIcon className={className} onClick={onClick} />
    </button>
  );
};

const Apartments = ({
  data,
  setContactModalOpen,
  header_title,
  header_text,
}) => {
  const slider = useRef(null);
  const [current_slide, setCurrentSlide] = useState(0);
  const [view, setView] = useState(0);

  const formatted_data = !!view
    ? data?.filter((item) => !!item?.underConstruction)
    : data?.filter((item) => !!!item?.underConstruction);

  const slider_settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    draggable: true,
    centerMode: true,
    centerPadding: "400px",
    useTransform: false,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
    },
    responsive: [
      {
        breakpoint: 1820,
        settings: {
          centerPadding: "300px",
        },
      },
      {
        breakpoint: 1640,
        settings: {
          centerPadding: "200px",
        },
      },
      {
        breakpoint: 1400,
        settings: {
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 1140,
        settings: {
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 1000,
        settings: {
          centerPadding: "0px",
        },
      },
    ],
  };

  return (
    <section id="apartamenty-section" className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__header`}>
          <h3
            dangerouslySetInnerHTML={{
              __html: header_title,
            }}
          />
          <CordsIcon />
          <p
            dangerouslySetInnerHTML={{
              __html: header_text,
            }}
          />
        </div>
        <div className={`${mainClass}__buttons`}>
          <Button variant="white" active={!!!view} onClick={() => setView(0)}>
            Gotowe apartamenty
          </Button>
          <Button variant="white" active={!!view} onClick={() => setView(1)}>
            Apartamenty w budowie
          </Button>
        </div>
      </div>
      <Slider
        ref={slider}
        className={`${mainClass}__slider`}
        {...slider_settings}
      >
        {formatted_data?.map(
          (
            {
              name,
              building,
              floor,
              rooms,
              area,
              price,
              promotionPrice,
              lowestPrice,
              image,
              plan,
              profit,
            },
            index
          ) => (
            <div key={index}>
              <div className={`${mainClass}__slider__item`}>
                <div className={`${mainClass}__slider__item__info`}>
                  <h3>{name}</h3>
                  <div className={`${mainClass}__slider__item__info__items`}>
                    <div
                      className={`${mainClass}__slider__item__info__items__item`}
                    >
                      <span>Budynek: </span>
                      <span>
                        <strong>{building}</strong>
                      </span>
                    </div>
                    <div
                      className={`${mainClass}__slider__item__info__items__item`}
                    >
                      <span>Piętro: </span>
                      <span>
                        <strong>{floor || "0"}</strong>
                      </span>
                    </div>
                    <div
                      className={`${mainClass}__slider__item__info__items__item`}
                    >
                      <span>Liczba pokoi: </span>
                      <span>
                        <strong>{rooms}</strong>
                      </span>
                    </div>
                    <div
                      className={`${mainClass}__slider__item__info__items__item`}
                    >
                      <span>Powierzchnia: </span>
                      <span>
                        <strong>{area}</strong> m<sup>2</sup>
                      </span>
                    </div>
                    <div className={`${mainClass}__slider__item__info__circle`}>
                      <span>
                        Wykończenie
                        <br />i wyposażenie
                        <br />w cenie
                      </span>
                    </div>
                  </div>
                  <div className={`${mainClass}__slider__item__info__prices`}>
                    {!!price && (
                      <div
                        className={classnames(
                          `${mainClass}__slider__item__info__prices__price`,
                          {
                            [`${mainClass}__slider__item__info__prices__price--is-promotion`]:
                              !!promotionPrice,
                          }
                        )}
                      >
                        <span>Cena łączna netto:</span>
                        <span>
                          <strong>{price}</strong> zł
                        </span>
                      </div>
                    )}
                    {!!promotionPrice && (
                      <div
                        className={`${mainClass}__slider__item__info__prices__price`}
                      >
                        <span>Cena promocyjna netto:</span>
                        <span>
                          <strong>{promotionPrice}</strong> zł
                        </span>
                      </div>
                    )}
                  </div>
                  {!!profit && (
                    <div
                      className={`${mainClass}__slider__item__info__calculator`}
                    >
                      <div
                        className={`${mainClass}__slider__item__info__calculator__top`}
                      >
                        <span>Roczny przychód</span>
                        <p>właściciela apartamentu nawet do:</p>
                      </div>
                      <div
                        className={`${mainClass}__slider__item__info__calculator__bottom`}
                      >
                        <span>
                          {profit} <span>zł netto*</span>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className={`${mainClass}__slider__item__plan`}>
                  <img src={image?.localFile?.publicURL} alt="" />
                  <div className={`${mainClass}__slider__item__plan__action`}>
                    <Button
                      variant="dark"
                      icon={<MessagesIcon />}
                      onClick={() =>
                        setContactModalOpen({
                          from: "apartments",
                          investment: name,
                        })
                      }
                    >
                      Zapytaj
                    </Button>
                    <Button
                      external
                      variant="grey"
                      href={plan?.localFile?.publicURL}
                      icon={<PdfIcon />}
                    >
                      Pobierz rzut
                    </Button>
                  </div>
                </div>
                {current_slide === index && (
                  <>
                    <CustomArrow
                      className={`${mainClass}__slider__item__arrow ${mainClass}__slider__item__arrow--prev`}
                      onClick={() => slider?.current?.slickPrev()}
                    />
                    <CustomArrow
                      className={`${mainClass}__slider__item__arrow ${mainClass}__slider__item__arrow--next`}
                      onClick={() => slider?.current?.slickNext()}
                    />
                  </>
                )}
                {!!lowestPrice && (
                  <span className={`${mainClass}__slider__promotion-info`}>
                    Najniższa cena netto apartamentu w ciągu ostatnich 30 dni:{" "}
                    {lowestPrice} zł
                  </span>
                )}
              </div>
              {current_slide === index && (
                <div className={`${mainClass}__slider__disclamer`}>
                  <p>
                    *Inwestycja polega na zakupie indywidualnego lokalu
                    apartamentowego oraz zawarciu umowy najmu na okres 10 lat.
                    Dochód, jaki otrzymuje właściciel stanowi 70% przychodu
                    netto (po odjęciu podatku VAT) z tytułu podnajmu lokalu. Do
                    wyliczeń zostały użyte średnie rynkowe ceny wynajmu oraz
                    średnie obłożenie podobnych apartamentów, z uwzględnieniem
                    sezonu niskiego i wysokiego. Kalkulacja zakłada
                    niekorzystanie z pobytów właścicielskich i uwzględnia
                    koszty: obsługi płatności i transakcji internetowych,
                    prowizji podmiotów trzecich, takich jak: portale
                    rezerwacyjne, biura podróży, pośrednicy turystyczni i tym
                    podobnych. W trakcie trwania umowy najmu właściciel ponosi
                    koszty: ubezpieczenia lokalu, podatku od nieruchomości oraz
                    kosztów utrzymania lokalu. Niniejsze informacje nie stanowią
                    oferty w rozumienie Art. 66 § 1 Kodeksu Cywilnego i nie
                    stanowią gwarancji uzyskania podobnych wyników oraz
                    świadczeń w przyszłości. Szczegóły dostępne są w biurze
                    sprzedaży.
                  </p>
                </div>
              )}
            </div>
          )
        )}
      </Slider>
    </section>
  );
};

export default Apartments;
