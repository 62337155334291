import "./styles.scss";

import React from "react";

const mainClass = "home-advantages-investment__more-info";

const AdvantagesInvestmentMoreInfo = ({ data }) => {
  return (
    <div id="lokalizacja-section" className={mainClass}>
      <div className={`${mainClass}__text`}>
        <h3>{data?.advantages_location_title}</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: data?.advantages_location_text,
          }}
        />
      </div>
      <div className={`${mainClass}__items`}>
        {data?.advantagesLocation?.map(({ image, text }, index) => (
          <div key={index} className={`${mainClass}__items__item`}>
            <img src={image?.localFile?.publicURL} alt="" />
            <span
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdvantagesInvestmentMoreInfo;
