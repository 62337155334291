import "./styles.scss";

import React from "react";
import Slider from "react-slick";

import HotelGroupIcon from "icons/condo-hotel-group.svg";
import PartnerIcon from "icons/partner-sign.svg";
import SpectrumLogoIcon from "icons/spectrum-logo.svg";
import VistaLogo from "images/logos/logo-vista.png";
import BergoLogo from "images/logos/bergo-logo.png";
import TremontiLogo from "images/logos/tremonti-szklarska-poreba.png";
import TremontiResortLogo from "images/logos/tremonti-resort.png";
import TremontiHotelLogo from "images/logos/tremonti-hotel.png";

const mainClass = "home-partners";

const slider_settings = {
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: false,
  draggable: true,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 420,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Partners = ({ data }) => {
	return (
		<section id="inwestor-section" className={mainClass}>
			<div className="container">
				<div className={`${mainClass}__header`}>
					<h3>
						Doświadczeni
						<br />i pewni partnerzy
					</h3>
				</div>
				<div className={`${mainClass}__content`}>
					<div className={`${mainClass}__content__col`}>
						<div className={`${mainClass}__content__col__header`}>
							<h4>{data?.[0]?.title}</h4>
							<span>{data?.[0]?.subText}</span>
						</div>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://condo.pl/uslugi-operatorskie"
						>
							<HotelGroupIcon />
						</a>
						<PartnerIcon />
						<div className={`${mainClass}__investments`}>
							<h5>Obsługiwane inwestycje</h5>
							<Slider {...slider_settings}>
								<div>
									<img src={VistaLogo} alt="" />
								</div>
								<div>
									<img src={BergoLogo} alt="" />
								</div>
								<div>
									<img src={TremontiLogo} alt="" />
								</div>
								<div>
									<img src={TremontiResortLogo} alt="" />
								</div>
								<div>
									<img src={TremontiHotelLogo} alt="" />
								</div>
							</Slider>
						</div>
						<p
							dangerouslySetInnerHTML={{
								__html: data?.[0]?.text,
							}}
						/>
					</div>
					<div className={`${mainClass}__content__col`}>
						<div className={`${mainClass}__content__col__header`}>
							<h4>{data?.[1]?.title}</h4>
							<span>{data?.[1]?.subText}</span>
						</div>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://spectrumdevelopment.pl"
						>
							<SpectrumLogoIcon />
						</a>
						<div
							dangerouslySetInnerHTML={{
								__html: data?.[1]?.text,
							}}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Partners;
