import "./styles.scss";

import React, { useEffect, useState } from "react";

import StepsModelImage from "images/steps-model.png";
import CloudsImage from "images/clouds.png";

const mainClass = "home-steps-model";

const StepsModel = () => {
  const [position, setPosition] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const section = document.getElementsByClassName(mainClass)[0];
      const window_width = window.innerWidth;

      let offset = 3;

      if (window_width < 900) {
        offset = 10;
      }

      if (window_width < 530) {
        offset = 40;
      }

      setPosition(
        (section?.offsetTop - section?.offsetHeight * 0.7 - window?.scrollY) /
          offset
      );
    });
  }, []);

  return (
    <section className={mainClass}>
      <img src={StepsModelImage} alt="" />
      <div className={`${mainClass}__clouds`} style={{ top: position }}>
        <img src={CloudsImage} alt="" />
      </div>
    </section>
  );
};

export default StepsModel;
