const scrollToElement = (element, offset = 80) => {
  if (element === "/") {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  } else {
    const elem = document.querySelector(element);

    window.scrollBy({
      top: elem.getBoundingClientRect().top - offset,
      behavior: "smooth",
    });
  }
};

export default scrollToElement;
