import "/node_modules/simplelightbox/dist/simple-lightbox.min.css";
import "./styles.scss";

import React, { useEffect, useState } from "react";
import SimpleLightbox from "simplelightbox";
import classnames from "classnames";
import Slider from "react-slick";

import CheckmarkIcon from "icons/checkmark.svg";
import ArrowIcon from "icons/arrow.svg";

const mainClass = "home-gallery__daily";

const CustomArrow = (props) => {
  const { className, onClick } = props;
  return (
    <button aria-label="Zamknij">
      <ArrowIcon className={className} onClick={onClick} />
    </button>
  );
};

const Daily = ({ items }) => {
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    const lightbox = new SimpleLightbox(`.${mainClass}__items a`, {
      spinner: true,
    });

    return () => {
      lightbox.destroy();
    };
  }, [selected]);

  const slider_settings = {
    infinite: true,
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
    useTransform: false,
    nextArrow: (
      <CustomArrow
        className={`${mainClass}__arrow ${mainClass}__arrow--next`}
      />
    ),
    prevArrow: (
      <CustomArrow
        className={`${mainClass}__arrow ${mainClass}__arrow--prev`}
      />
    ),
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 410,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className={mainClass}>
      <div className="container">
        <Slider className={`${mainClass}__menu`} {...slider_settings}>
          {items?.map(({ date }, index) => (
            <div>
              <button
                key={index}
                onClick={() => setSelected(index)}
                className={classnames(`${mainClass}__menu__item`, {
                  [`${mainClass}__menu__item--active`]: selected === index,
                })}
              >
                <div className={`${mainClass}__menu__item__status`}>
                  {selected === index && <CheckmarkIcon />}
                </div>
                <span>{date}</span>
              </button>
            </div>
          ))}
        </Slider>
        <div key={selected} className={`${mainClass}__items`}>
          {items?.[selected]?.images
            ?.map(({ localFile }, index) => (
              <a
                key={index}
                href={localFile?.publicURL}
                className={`${mainClass}__items__item`}
                style={{ backgroundImage: `url(${localFile?.publicURL})` }}
              >
                &nbsp;
              </a>
            ))
            ?.reverse()}
        </div>
      </div>
    </div>
  );
};

export default Daily;
