import "./styles.scss";

import React, { useEffect, useState } from "react";

import AdvantagesInvestmentSlider from "./components/Slider";
import AdvantagesInvestmentMoreInfo from "./components/MoreInfo";

import RootImage from "images/root.png";
import MapImage from "icons/map-old.svg";

const mainClass = "home-advantages-investment";

const AdvantagesInvestment = ({ slider_items, more_data }) => {
  const [position, setPosition] = useState(0);

  useEffect(() => {
    const element = document.getElementsByClassName(
      `${mainClass}__map__wrapper`
    )[0];

    element.scrollTo((element.scrollWidth - element.offsetWidth) / 1.6, 0);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let section = document.getElementsByClassName(mainClass)[0];
      const window_width = window.innerWidth;

      let offset = 10;

      if (window_width < 900) {
        offset = 20;
      }

      if (window_width < 530) {
        offset = 40;
      }

      setPosition(
        (section?.offsetTop - section?.offsetHeight * 0.15 - window?.scrollY) /
          offset
      );
    });
  }, []);

  return (
    <section className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__header`}>
          <h3>Atuty inwestycji</h3>
        </div>
        <AdvantagesInvestmentSlider items={slider_items} />
        <AdvantagesInvestmentMoreInfo data={more_data} />
        <div className={`${mainClass}__map`}>
          <div className={`${mainClass}__map__wrapper`}>
            <MapImage />
          </div>
        </div>
      </div>
      <img
        className={`${mainClass}__root-bg`}
        src={RootImage}
        alt=""
        style={{ bottom: position }}
      />
    </section>
  );
};

export default AdvantagesInvestment;
