import "./styles.scss";

import React from "react";
import Button from "components/Button";

import BirdsImage from "images/birds.png";
import ShellImage from "images/shell.png";

import MouseImage from "icons/mouse-scroll.svg";
import CordsIcon from "icons/cords.svg";

const mainClass = "home-about-investment";

const AboutInvestment = ({
  data,
  magical_microclimate_title,
  magical_microclimate_text,
}) => {
  return (
    <section id="o-inwestycji-section" className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__header`}>
          <img src={BirdsImage} alt="" />
          <h2>O inwestycji</h2>
        </div>
        <div className={`${mainClass}__content`}>
          <div className={`${mainClass}__content__item`}>
            <div className={`${mainClass}__content__item__text`}>
              <h3>{data?.[0]?.title}</h3>
              <span
                dangerouslySetInnerHTML={{
                  __html: data?.[0]?.subText,
                }}
              />
              <p>{data?.[0]?.text}</p>
              <Button href="#apartamenty" icon={<CordsIcon />}>
                Wybierz apartament
              </Button>
            </div>
            <div className={`${mainClass}__content__item__image`}>
              <img src={data?.[0]?.image?.localFile?.publicURL} alt="" />
            </div>
          </div>
          <div className={`${mainClass}__content__item`}>
            <div className={`${mainClass}__content__item__image`}>
              <img src={data?.[1]?.image?.localFile?.publicURL} alt="" />
            </div>
            <div className={`${mainClass}__content__item__text`}>
              <h3>{data?.[1]?.title}</h3>
              <span
                dangerouslySetInnerHTML={{
                  __html: data?.[1]?.subText,
                }}
              />
              <p>{data?.[1]?.text}</p>
            </div>
          </div>
          <img
            className={`${mainClass}__content__shell`}
            src={ShellImage}
            alt=""
          />
        </div>
        <div className={`${mainClass}__magic-microclimate`}>
          <h2>{magical_microclimate_title}</h2>
          <p
            dangerouslySetInnerHTML={{
              __html: magical_microclimate_text,
            }}
          />
          <MouseImage />
        </div>
      </div>
    </section>
  );
};

export default AboutInvestment;
