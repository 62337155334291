import "./styles.scss";

import React, { useState } from "react";

import GallerySlider from "./Slider";
import Daily from "./Daily";
import Button from "components/Button";

import LampIcon from "icons/lamp.svg";
import MapIcon from "icons/map-icon.svg";

const mainClass = "home-gallery";

const Gallery = ({ gallery_items, daily_items }) => {
  const [view, setView] = useState(0);

  return (
    <section id="galeria-section" className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__header`}>
          <h3>Galeria</h3>
        </div>
        <div className={`${mainClass}__buttons`}>
          <Button
            variant="white"
            icon={<LampIcon />}
            active={!!!view}
            onClick={() => setView(0)}
          >
            Wizualizacje
          </Button>
          <Button
            variant="white"
            icon={<MapIcon />}
            active={!!view}
            onClick={() => setView(1)}
          >
            Dziennik budowy
          </Button>
        </div>
      </div>
      {!!view ? (
        <Daily items={daily_items} />
      ) : (
        <GallerySlider items={gallery_items} />
      )}
    </section>
  );
};

export default Gallery;
