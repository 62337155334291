import "./styles.scss";

import React, { useEffect } from "react";

import FormContent from "components/Form";

import MessageIcon from "icons/message.svg";
import ExitIcon from "icons/cross.svg";

const mainClass = "contact-modal";

const ContactModal = ({ investment, search, onExit }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__content`}>
          <div className={`${mainClass}__content__header`}>
            <MessageIcon />
            <h4>Wypełnij formularz</h4>
            <span>DOPASUJEMY OFERTĘ DO TWOICH POTRZEB</span>
            <ExitIcon
              onClick={onExit}
              className={`${mainClass}__content__exit`}
            />
          </div>
          <div className={`${mainClass}__content__content`}>
            <div className={`${mainClass}__content__content__wrapper`}>
              <FormContent search={search} investment={investment} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactModal;
